<template>
  <div class="col-md-12 default">
    <point-mgmt />

  </div>
</template>

<script>
import pointMgmt from "./PointMgmt.vue";

export default {
  name: "PointManagement",
  components: {
    pointMgmt,
  },
  props: [],
  data() {
    return {
      
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },

  methods: {
   
  },
};
</script>
